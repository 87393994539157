import React, { useState, useRef, useCallback, forwardRef, useImperativeHandle } from 'react';
import ReactCrop from 'react-image-crop';
import './ReactCrop.css';
import css from './ImageCropperWrapper.module.css';

const ImageCropperWrapper = forwardRef(({ children, onCrop, aspect = 1, onChange }, ref) => {
  const [src, setSrc] = useState(null)
  const [crop, setCrop] = useState()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const imageRef = useRef(null)
  const inputRef = useRef(null)

  useImperativeHandle(ref, () => ({
    openFileSelector: () => inputRef.current?.click()
  }))

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0]
      const reader = new FileReader()
      reader.addEventListener('load', () => {
        setSrc(reader.result)
        setIsModalOpen(true)
      })
      reader.readAsDataURL(file)

      // Call the original onChange function
      if (onChange) {
        onChange(e);
      }
    }
  }

  const onImageLoad = useCallback((image) => {
    const width = Math.min(image.width, image.height)
    setCrop({
      unit: 'px',
      width: 300,
      height: 300,
      aspect,
      x: (image.width / 2) - 150,
      y: 100, // (100 - 90 * (image.height / image.width)) / 2,
    })
  }, [aspect])

  const getCroppedImg = useCallback(() => {
    if (!crop || !imageRef.current) return

    const canvas = document.createElement('canvas')
    const scaleX = imageRef.current.naturalWidth / imageRef.current.width
    const scaleY = imageRef.current.naturalHeight / imageRef.current.height
    canvas.width = crop.width
    canvas.height = crop.height
    const ctx = canvas.getContext('2d')

    if (!ctx) return

    ctx.drawImage(
      imageRef.current,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    )

    canvas.toBlob((blob) => {
      if (!blob) return
      if (onCrop) {
        onCrop(blob)
      }
      setIsModalOpen(false)
    })
  }, [crop, onCrop])

  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        ref: inputRef,
        onChange: onSelectFile,
        ...child.props
      })
    }
    return child
  })

  return (
    <div className={css.imageCropperWrapper}>
      {childrenWithProps}
      {isModalOpen && (
        <div className={css.imageCropperModal}>
          <div className={css.imageCropperContent}>
            <div className={css.imageCropperHeader}>
              <h3 className={css.imageCropperTitle}>Crop Image</h3>
            </div>
            {src && (
              <ReactCrop
                crop={crop}
                onChange={(c) => setCrop(c)}
                aspect={aspect}
              >
                <img
                  ref={imageRef}
                  src={src}
                  alt="Crop me"
                  onLoad={(e) => onImageLoad(e.currentTarget)}
                />
              </ReactCrop>
            )}
            <div className={css.imageCropperFooter}>
              <button type="button" className={`${css.button} ${css.buttonSecondary}`} onClick={() => setIsModalOpen(false)}>Cancel</button>
              <button type="button" className={`${css.button} ${css.buttonPrimary}`} onClick={getCroppedImg}>Crop Image</button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
});

ImageCropperWrapper.displayName = 'ImageCropperWrapper';

export default ImageCropperWrapper;
