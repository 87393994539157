export const forms = {
    // EditListingDetailsForm: {
    //     checkboxLimits: {
    //         pub_Industry1: 3,
    //         pub_projecttech: 5
    //     }
    // },
    null: {
        checkboxLimits: {
            pub_Technologysweetspot: 5,
            pub_Industrysweetspot: 3
        }
    }
};