// USER TYPES
export const USER_TYPE_PROVIDER = 'provider';
export const USER_TYPE_MANUFACTURER = 'customer';

// LISTING TYPES
export const LISTING_TYPE_PROVIDER = 'free-listing';
export const LISTING_TYPE_MANUFACTURER = 'manufacturerprojectlisting';

// USER FIELDS
export const WEBSITE_USER_FIELD = 'website';
