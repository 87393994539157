/*
 * Renders a group of checkboxes that can be used to select
 * multiple values from a set of options.
 *
 * The corresponding component when rendering the selected
 * values is PropertyGroup.
 *
 */

import React from 'react';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { arrayOf, bool, node, shape, string, number } from 'prop-types';
import classNames from 'classnames';
import { FieldArray } from 'react-final-form-arrays';
import { FieldCheckbox, ValidationError } from '../../components';

import css from './FieldCheckboxGroup.module.css';

const FieldCheckboxRenderer = props => {
  const {
    className,
    rootClassName,
    label,
    optionLabelClassName,
    twoColumns,
    id,
    fields,
    options,
    meta,
    intl,
    maxChecked
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const listClasses = twoColumns ? classNames(css.list, css.twoColumns) : css.list;

  // space char and trim are a hack to prevent fall-through due to falsy string check in react-intl
  // see https://github.com/formatjs/formatjs/issues/607 for details
  // TODO: set config for fallback?
  const helperLink = intl.formatMessage({id: "FormFieldHelperLink." + fields.name, defaultMessage: " "});

  const handleChange = (event, option) => {
    const currentValue = [...(fields.value || [])];  // Create a new array to trigger update
    const isChecked = event.target.checked;

    if (isChecked) {
      // Add the value if it's not already in the array and we're under the limit
      if (!currentValue.includes(option.key) && currentValue.length < maxChecked) {
        currentValue.push(option.key);
      }
    } else {
      // Remove all occurrences of the value
      const newValue = currentValue.filter(value => value !== option.key);
      currentValue.splice(0, currentValue.length, ...newValue);
    }

    // Update the fields value
    fields.value = [...currentValue];
  };

  return (
    <fieldset className={classes}>
      {label ?
         helperLink.trim() ? <legend>
              <a target="_blank" href={helperLink} class={css.helperLink}>{label}</a>&nbsp;<i>(click for more info)</i>
            </legend> : <legend>{label}</legend>
      : null}
      {maxChecked && maxChecked !== Infinity && (
        <legend className={css.limitMessage}>
          A maximum of {maxChecked} {maxChecked > 1 ? "options" : "option"} can be selected.
        </legend>
      )}
      <ul className={listClasses}>
        {options.map((option, index) => {
          const fieldId = `${id}.${option.key}`;
          const textClassName = optionLabelClassName;
          const textClassNameMaybe = textClassName ? { textClassName } : {};
          const isChecked = (fields.value || []).includes(option.key);
          const isDisabled = !isChecked && (fields.value || []).length >= maxChecked;

          return (
            <li key={fieldId} className={css.item}>
              <FieldCheckbox
                id={fieldId}
                name={fields.name}
                label={option.label}
                value={option.key}
                onChange={(event) => handleChange(event, option)}
                checked={isChecked}
                disabled={isDisabled}
                {...textClassNameMaybe}
              />
            </li>
          );
        })}
      </ul>
      <ValidationError fieldMeta={{ ...meta }} />
    </fieldset>
  );
};

FieldCheckboxRenderer.defaultProps = {
  rootClassName: null,
  className: null,
  label: null,
  twoColumns: false,
  maxChecked: Infinity
};

FieldCheckboxRenderer.propTypes = {
  rootClassName: string,
  className: string,
  id: string.isRequired,
  label: node,
  options: arrayOf(
    shape({
      key: string.isRequired,
      label: node.isRequired,
    })
  ).isRequired,
  twoColumns: bool,
  maxChecked: number
};

const FieldCheckboxGroup = props => <FieldArray component={FieldCheckboxRenderer} {...props} />;

// Name and component are required fields for FieldArray.
// Component-prop we define in this file, name needs to be passed in
FieldCheckboxGroup.propTypes = {
  name: string.isRequired,
  maxChecked: number
};

export default compose(
  injectIntl
)(FieldCheckboxGroup);
